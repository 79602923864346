
    import Vue from 'vue'
    import api from '@api/activity/apiMethod'
    import {mapGetters} from 'vuex'
    import dict from '@custom/dict'

    const PortalHeader = () => import('../../component/common/PortalHeader.vue')
    const NewsBlock = () => import('../../component/common/NewsBlock.vue')
    const NCommonTitle = () => import('@corecp/NCommonTitle.vue')
    const PortalFooter = () => import('../../component/common/PortalFooter.vue')

    export default Vue.extend({
        name: 'ActivityDetail',
        components: {
            PortalHeader,
            NewsBlock,
            NCommonTitle,
            PortalFooter
        },
        computed: {
            ...mapGetters([
                'userInfo',
                'comConfig'
            ])
        },
        data () {
            return {
                articleDetail: {
                },
                commentList: [],
                pagingInfo: {
                    onChange: (page: any) => {
                        console.log(page)
                    },
                    ...dict.commonObj.tablePagingInfoOpt
                },
                eventId: undefined,
                commentInfo: {
                    commentText: undefined
                }
            }
        },
        methods: {
            async queryArticle () {
                const self = this as any
                api.queryEventList({
                    data: {
                        filterConds: self.buildFilterConds({
                            eventId: self.eventId
                        })
                    },
                    success (res: any) {
                        self.articleDetail = res.data[0]
                    }
                })
            },
            async queryComment () {
                const self = this as any
                api.queryComment({
                    data: {
                        filterConds: [
                            {
                                fieldName: 'eventId',
                                fieldValue: self.eventId
                            }
                        ]
                    },
                    success (res: any) {
                        self.commentList = res.data
                    }
                })
            },
            buildFilterConds (obj: any) {
                const filterConds = []
                for (const key of Object.keys(obj)) {
                    if (obj[key]) {
                        filterConds.push({
                            fieldName: key,
                            fieldValue: obj[key]
                        })
                    }
                }
                return filterConds
            },
            sendComment () {
                const self = this as any
                self.$confirm({
                    content: '是否新增评论？',
                    onOk() {
                        api.addComment({
                            data: {
                                eventId: self.eventId,
                                memberId: self.userInfo.memberId,
                                comment: self.commentInfo.commentText
                            },
                            success (res: any) {
                                self.$message.success('新增成功')
                                self.queryComment()
                            }
                        })
                    }
                })
            }
        },
        async created () {
            const self = this as any
            self.eventId = self.$route.query.event_id
            await self.queryArticle()
            await self.queryComment()
        }
    })
